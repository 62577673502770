<template>
  <div>
    <top-nav :showLogo="false" :title="$t('记录')"></top-nav>
    <div class="list-cont">
      <!--      <van-list-->
      <!--          v-model="loading"-->
      <!--          :finished="finished"-->
      <!--          :loading-text="$t('加载中')"-->
      <!--          :finished-text="$t('没有更多了')"-->
      <!--          @load="onLoad"-->
      <!--      >-->
      <!--        -->
      <!--      </van-list>-->
      <div class="list-item" v-for="(item,index) in 10" :key="index">
        <div class="item-info">
          <div class="type">
            <img class="icon" src="../../assets/image/bank/icon1.png">
            {{ $t('充值') }}

<!--            <img class="icon" src="../../assets/image/bank/icon2.png">-->
<!--            {{ $t('提出') }}-->
          </div>
          <div class="key">
            {{ $t('数量') }}
          </div>
          <div class="value">
          123213
          </div>
        </div>
        <div class="item-info">
          <div class="key">{{ $t('日期') }}</div>
          <div class="value tiem">2023-11-18 11:22:26</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopNav from '@/components/topNav.vue'
import {mapState} from "vuex";

export default {
  name: "record",
  components: {TopNav},
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1,
    }
  },
}
</script>

<style scoped lang="less">

.list-cont {
  padding: 1rem;

  .list-item {
    padding: 1.8rem 1.1rem;
    background: #1B1B1B;
    border-radius: 1.07rem;
    margin-bottom: 1rem;

    .status {
      text-align: right;
      color: #B5904E;
      font-size: 0.86rem;
      padding-bottom: 0.2rem;
    }
    .type{
      overflow: hidden;
      font-size: 1.07rem;
      color: #E7BD71;
      line-height: 2.14rem;
      .icon{
        padding-right: 1rem;
        float: left;
        width: 2.14rem;
        height: 2.14rem;
      }
    }

    .item-info {
      overflow: hidden;

      .key {
        padding-left: 3.14rem;
        float: left;
        font-size: 0.93rem;
        color: #6E6045;
        line-height: 2.14rem;
      }

      .value {
        float: right;
        font-size: 1.07rem;
        color: #E7BD71;
        line-height: 2.14rem;
      }

      .tiem {
        font-size: 0.86rem;
        color: #6E6045;
      }
    }
  }
}
</style>